
<template>
  <div>
      <card-component title="Grup Bazlı Toplu Güncelleme" icon="domain">
        <form @submit.prevent="save">
          <b-field label="Grup Adı">
            <b-input size="is-small" v-model="form.group" icon="domain" placeholder="Grup Adı" name="group"/>
          </b-field>
          <hr/>
          <b-field label="Durum">
            <b-switch v-model="form.status" type="is-success" size="is-small"></b-switch>
          </b-field>
          <hr/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button native-type="submit" type="is-primary" size="is-small" :loading="$store.state.loading.length > 0" :disabled="$store.state.loading.length > 0">Güncelle</b-button>
            </div>
            <div class="control">
              <b-button type="is-primary is-outlined" size="is-small" @click="$emit('close')">Kapat</b-button>
            </div>
          </div>
        </form>
      </card-component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import { BankAccountService as Service } from '@/services'

export default {
  name: 'WithdrawGroupChange',
  components: { CardComponent },
  data () {
    return {
      form: {
        group: null,
        status: false
      }
    }
  },
  methods: {
    save () {
      Service.multiUpdate({...this.form })
          .then(() => {
            this.$buefy.toast.open({
              message: 'Güncellendi',
              type: 'is-success'
            })
            this.$emit('close')
          })
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    },
  }
}
</script>

<style>
.vm--modal {
  height: auto !important;
}
</style>
